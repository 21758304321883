// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // client live domain pointed urls
  // baseUrl: 'https://node.moteegowns.co.uk/api/v1/',
  // webUrl: 'https://moteegowns.co.uk/', // need to check after website created
  // chatSocketUrl: 'https://node.moteegowns.co.uk'

  // staging domain pointed urls

  baseUrl: 'https://node-moteemaids.mobiloitte.io/api/v1/',

  webUrl: 'https://motee-maids-customer.mobiloitte.org/',
  chatSocketUrl: 'https://node-moteemaids.mobiloitte.io',





  // baseUrl : 'http://172.16.6.83:3031/api/v1/' // Izhar Local

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
